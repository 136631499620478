.notifiactionContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 0;
}
.notificationListContainer{
    width: 600px;
}
.deleteNotifyIcon{
    cursor: pointer;
    transition: .5s all ease;
}
.deleteNotifyIcon:hover{
    color: red;
}