.yayinEvleriContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 40px;
}

.yayinevleriContainerHeader{
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.publisherItem{
    width: 30%;
    margin-bottom: 20px;
    text-decoration: none;
    color: var(--softBlue );
    transition: .5s all ease;
}
.publisherItem:hover{
    opacity: .7;
}
.searchPublisherInput{
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width:1100px) {
    .searchPublisherInput{
        width: 400px;
    } 
}

@media screen and (max-width:750px) {
    .searchPublisherInput{
        width: 100%;
    } 
}

@media screen and (max-width:600px) {
    .publisherItem{
        width: 40%;
    } 
}
@media screen and (max-width:500px) {
    .publisherItem{
        font-size: 12px;
    } 
}