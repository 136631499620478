.footer{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #e7e7e7;
}

/* #777 */
.footer-col{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
}

.footer-col-1{
}
.footer-col-2{
}
.footer-col-3{
}

.footer-col-3 p{
    color: #777;
}


.footerImg{
    width: 100px;
}
.footer-social-links{
    display: flex;
    flex-direction: row;
    font-size: 30px;
}
.footer-social-links * {
    margin-right: 5px;
    transition: .5s all ease;
    cursor: pointer;
}
.footer-social-links *:hover{
    opacity: .75;
}

.footer-col-2 p {
    color: #777;
    margin: 7px 0;
    cursor: pointer;
}
.footer-col-2 p:hover{
    text-decoration: underline;
}

.addBultenContainer{
    display:flex;
    flex-direction:row;
}
.addBultenInput{
    border-radius: 0;
}
.addBultenButton{
    background-color: #777;
    border-radius: 0;
}


@media screen and (max-width:1300px) {
    .footer-col{
        width: 30%;
    }
}

@media screen and (max-width:800px) {
    .footer-col{
        width: 50%;
        padding: 10px 0;
        flex-direction: column;
        align-items: center;
    }
    .footer{
        flex-wrap: wrap;
    }
}

@media screen and (max-width:500px) {
    .footer-col{
        width: 100%;
        padding: 0 20px;
        margin: 20px 0;
    }
}