.readingTargetsContainer {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.readingTargetsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.readingTargetsBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.readingTargetCard {
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background: #f9f9f9;
}

.readingTargetDetails {
    margin-bottom: 15px;
}

.booksContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.bookCard {
    width: 100px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.bookCard img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.bookCard p {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
}

.bookCard:hover {
    transform: scale(1.05);
}

.noResults {
    text-align: center;
    font-size: 16px;
    color: #888;
}