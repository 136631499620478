.chatContainer{
    width:800px;
    height:600px;
    background-color: white;
    /* margin: 20px auto; */
    display: flex;
    flex-direction: row;
}

.chatLeftContainer{
    width:200px;
    height:100%;
    overflow-y: auto;
    background-color: var(--dkred);
    direction: rtl;
}

.chatLeftContainer::-webkit-scrollbar {
    width: 5px; 
}
  
.chatLeftContainer::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0); 
}

.chatLeftContainer * {
    direction: ltr;
}
.deleteChatIcon{
    color: 'white';
    cursor: pointer;
    font-size: 18px;
}
.deleteChatIcon:hover{
    transform: scale(1.05);
}
.chatRightContainer{
    flex-grow: 1;
    height:100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.chatRightContainerHeader{
    line-height: 50px;
    font-size: 26px;
    color: white;
    box-sizing: border-box;
    padding-left: 30px;
    width: 100%;
    height: 50px;
    background-color: var(--dkred);
}

.chatRightContainerInputContainer{
    width: 100%;
    min-height: 30px;
    display: flex;
    flex-direction: row;
}

.sendIconChat{
    transition: .5s all ease;
}

.sendIconChat:hover{
    border-color: var(--softBlue);
    color:  var(--softBlue);
}

.chatRightContainerMessagesContainer{
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    height: 520px;
}

.chatBox{
    display: flex;
    flex-direction: column-reverse;
    padding: 10px 0;
}

.messageBox{
    max-width: 90%;
    margin: 20px 10px;
    line-height: 25px;
    display: flex;
}
.chatBoxMessage{
    margin: 0 10px;
    transition: .5s all ease;
}

@media screen and (max-width:850px) {
    .chatContainer{
        width: 600px;
    }
}

@media screen and (max-width:650px) {
    .chatLeftContainer {
        min-width: 70px !important;
        max-width: 70px;
    }
    .chatContainer{
        width: 400px;
    }
}

@media screen and (max-width:450px) {
    .chatContainer{
        width: calc(100vw);
    }
}
