.profileDrawerContainer{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    box-sizing: border-box;
}
.settingsIcon{
    position: absolute;
    top: 10px;
    right: 20px;
    transition: .5s all ease;
}

.settingsIcon:hover{
    color: var(--softBlue);
}

.addBookToLibraryButton{
    transition: .5s all ease;
    cursor: pointer;
    opacity: .75;
}
.addBookToLibraryButton:hover{
    opacity: 1;
}

.actionsContainer{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;

}
.actionsContainer span{
    cursor: pointer;
    transition: .5s all ease;
}
.actionsContainer span:hover{
    color: var(--softBlue);;
}

.userBiyoContainer{
    width: 100%;
    padding: 10px 0 0 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

}
.userBiyoContainer span{
    margin-bottom:10px;
    color:rgba(0,0,0,.8);
}

.userReadStatusesComponent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 10px;
}

.optionContainer{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.optionContainer{
    cursor: pointer;
    transition: .5s all ease;
    margin-bottom: 20px;
}
.optionContainerItem:hover{
    color: var(--softBlue);
}
.followerContainer{
    width: 100%;
    height: 300px;
    overflow-y:auto ;
}

.badgeContainer{
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profileCommentImage{
    width: 50px;
    height: 100px;
    object-fit: contain;
}
.delete-hover{
    transition: .5s all ease;
}
.delete-hover:hover{
    color: red;
}


.blogProfileContainer{
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
}

.blogProfilePicture{
    width: 70%;
    cursor: pointer;
}
.blogProfilePicture img{
    width: 100%;
    max-height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

