.bookListComponent{
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.bookListComponentTitle{
    width: 100%;
    font-size: 20px;
    line-height: 40px;
}
.bookItem{
    width: 60px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 10px;
    border: 1px solid rgba(0,0,0,.2);
    cursor: pointer;
}
.bookItem img{
    width: 100%;
    height: 100%;
}