.kitaplarContainer{
    padding-left: 20px;
    padding-right: 20px;
}
.kitaplarBody{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.kitaplarHeader{
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
@media screen and (max-width:600px) {
    .kitaplarHeader{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .kitaplarHeader h2{
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .kitaplarBody {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}


@media screen and (max-width:450px) {
    .card-image {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width:350px) {
    .kitaplarBody{
        justify-content: center;
    }
}