.rightColumn{
    width:225px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.iframe iframe{
    width: 225px;
    height: 190px;
}
.rightColumnItem{
    width: 225px;
    margin: 10px 0;
    min-height: 150px;
    box-sizing: border-box;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 0;
}
.rightColumnItem img{
    max-height: 200px;
    max-width: 180px;
    object-fit: cover;
    margin: 0 auto;
}
.rightColumnItemShadowContainer{
    position: relative;
}
.rightColumnItemShadowBox{
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, rgba(0,0,0,0.9349240780911063) 34%, rgba(0,0,0,0.7730802603036876) 61%, rgba(0,0,0,0.44251626898047725) 86%, rgba(255,255,255,0) 100%);
    position: absolute;
    bottom: 0;
    color: yellow;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 0 5px 8px;
    box-sizing: border-box;
}
.rightColumnItemTitle{
    color:var(--softBlue);
    text-align: center;
    margin-top: 10px;
    text-decoration: none;
}

@media screen and (max-width:1200px) {
    .rightColumn{
        width:175px;
    }
    .iframe iframe{
        width: 175px;
        height: 150px;
    }
    .rightColumnItem{
        width: 175px;
        margin: 5px 0;
        padding: 5px;
    }
    .rightColumnItem img{
        max-height: 175px;
        max-width: 150px;
    }
}

@media screen and (max-width:950px) {
    .rightColumn{
        width:150px;
    }
    .iframe iframe{
        width: 150px;
        height: 100px;
    }
    .rightColumnItem{
        width: 150px;
    }
    .rightColumnItem img{
        max-height: 150px;
        max-width: 120px;
    }
}

@media screen and (max-width:750px) {
    .rightColumn{
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .rightColumnResponsiveTemp{
        width: 300px;
    }
    .iframe iframe{
        width: 300px;
        height: 200px;
        margin: 0 10px 0 0;
    }
}

@media screen and (max-width:550px) {
    .rightColumnResponsiveTemp{
        width: 250px;
        margin-top: 20px;
    }
}
@media screen and (max-width:350px) {
    .rightColumnResponsiveTemp{
        width: 250px;
    }
    .iframe iframe{
        width: 250px;
        height: 150px;
        margin: 20px 0;
    }
}