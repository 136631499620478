
.card{
    width: 200px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card-image{
    text-align: center;
    width: 120px;
    height: 170px;
    margin-bottom: 10px;
}
.card-image .shadowBox{
    max-width: 120px;
    height: 170px;
    border-radius: 10px;
}
.card-image img{
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}
.card-content{
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}
.shadowBox{
    position: relative;
}
.shadow{
    width: 100%;
    height: 40px;
    position: absolute;
    background: linear-gradient(0deg, rgba(0,0,0,0.9349240780911063) 34%, rgba(0,0,0,0.7730802603036876) 61%, rgba(0,0,0,0.44251626898047725) 86%, rgba(255,255,255,0) 100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    bottom: 0;
    color: yellow;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    box-sizing: border-box;
}

@media screen and (max-width:700px) {
    .card{
        width: 175px;
    }
    .card-image{
        text-align: center;
        width: 100px;
        height: 140px;
    }
    .shadowBox{
        max-width: 100px !important;
        height: 140px !important;
    }
    .card-content{
        width: 175px;
    }
}

@media screen and (max-width:450px) {
    .card{
        width: 150px;
        height: 350px;
    }
    .card-image{
        width: 150px;
        height: 150px;
    }
    .shadowBox{
        max-width: 150px;
        height: 150px;
        border-radius: 10px;
        object-fit: contain;
    }
    .card-content{
        width: 150px;
        height: 115px;
    }
}

@media screen and (max-width:380px) {
    .cevirmenlerBody{
        justify-content: center;
    }
    .card{
        width: 200px;
        height: 350px;
        text-align: center;
    }
    .card-image{
        width: 200px;
        height: 200px;
    }
    .shadowBox{
        max-width: 200px;
        height: 200px;
    }
    .card-content{
        width: 200px;
        height: 150px;
    }
}